<template>
  <div class='icon-line-cart'>
    <svg xmlns="http://www.w3.org/2000/svg" width="24.936" height="23.469" viewBox="0 0 24.936 23.469">
      <path id="Path_38113" data-name="Path 38113" d="M24.792,17.624a.8.8,0,0,0-.622-.342L8.7,16.616a.8.8,0,0,0-.069,1.6l14.421.622-2.835,8.847H7.6L5.321,15.27a.8.8,0,0,0-.494-.6L1.093,13.2a.8.8,0,0,0-.585,1.489L3.827,16l2.32,12.631a.8.8,0,0,0,.787.655h.385L6.44,31.724a.667.667,0,0,0,.627.893h.616a2.4,2.4,0,1,0,3.568,0h5.233a2.4,2.4,0,1,0,3.568,0h.75a.667.667,0,1,0,0-1.333H8.016l.72-2H20.8a.8.8,0,0,0,.762-.556l3.334-10.4A.8.8,0,0,0,24.792,17.624ZM9.468,35.284a1.067,1.067,0,1,1,1.067-1.067A1.068,1.068,0,0,1,9.468,35.284Zm8.8,0a1.067,1.067,0,1,1,1.067-1.067A1.068,1.068,0,0,1,18.269,35.284Z" transform="translate(0 -13.148)" :fill="color"/>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'IconLineCart',
  props: {
    color: {
      type: String,
      required: false,
      default: '#bbb',
    },
  },
  components: {},
  data: () => ({
  }),
  created() {},
  computed: {},
  methods: {},
};
</script>

<style lang='scss' scoped>
</style>
